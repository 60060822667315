// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import cviLogo from "assets/images/logos/logo-capturevi.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Capture V.I.",
    image: cviLogo,
    route: "/",
  },
  socials: [],
  menus: [
    {
      name: "company",
      items: [{ name: "about us", route: "/about-us" }],
    },
    {
      name: "Financial",
      items: [
        { name: "CC Processing", route: "/services/financial/ccp" },
        { name: "Employee Retention Credit", route: "/services/financial/erc" },
        { name: "Mergers & Acquisitions", route: "/services/financial/ma" },
      ],
    },
    {
      name: "Consulting",
      items: [
        { name: "Business", route: "/services/consulting/business" },
        { name: "Technology", route: "/services/consulting/technology" },
      ],
    },
    {
      name: "help & support",
      items: [{ name: "contact us", route: "/contact-us" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://www.capturevi.com/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Capture V.I.{" "}
      </MKTypography>
      All rights reserved.
    </MKTypography>
  ),
};
