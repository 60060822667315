/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";

// // Material Kit 2 React components
// import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";

// Images
// import MAImage from "assets/images/backgrounds/services landing/ma-abstract.jpg";

import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import bgImage from "assets/images/backgrounds/services landing/red-wave.jpg";

const images = [
  {
    url: bgImage,
    title: "Credit Card Processing",
    width: "100%",
    route: "/services/financial/ccp",
  },
  {
    url: bgImage,
    title: "Employee Retention Credit",
    width: "100%",
    route: "/services/financial/erc",
  },
  {
    url: bgImage,
    title: "Mergers & Acquisitions",
    width: "100%",
    route: "/services/financial/ma",
  },
  {
    url: bgImage,
    title: "Business Consulting",
    width: "100%",
    route: "/services/consulting/business",
  },
  {
    url: bgImage,
    title: "Technology Consulting",
    width: "100%",
    route: "/services/consulting/technology",
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 150,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0,
    },
    "& .MuiImageMarked-root": {
      opacity: 1,
    },
    "& .MuiTypography-root": {
      border: "6px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.65,
  transition: theme.transitions.create("opacity"),
}));

// const ImageMarked = styled("span")(({ theme }) => ({
//   height: 3,
//   width: 18,
//   backgroundColor: theme.palette.common.white,
//   position: "absolute",
//   bottom: -2,
//   left: "calc(50% - 9px)",
//   transition: theme.transitions.create("opacity"),
// }));

export default function ServiceAreas() {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", minWidth: 300, width: "100%" }}>
      {images.map((image) => (
        <ImageButton
          component={Link}
          to={image.route}
          focusRipple
          key={image.title}
          style={{
            width: image.width,
          }}
        >
          <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="#B88746"
              sx={{
                position: "relative",
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {image.title}
              {/* <ImageMarked className="MuiImageMarked-root" /> */}
            </Typography>
          </Image>
        </ImageButton>
      ))}
    </Box>
  );
}
// function ServiceAreas() {
//   return (
//     <MKBox component="section" bgColor="#191919" position="relative" width="100%">
//       <Container disableGutters>
//         <Grid
//           container
//           display="grid"
//           gridTemplateColumns="1fr"
//           gridTemplateRows="[MA] 300px [CC] 300px [AP] 300px [TC] 300px [BC] 300px"
//         >
//           <Grid item gridRow="MA">
//             <Box
//               sx={{
//                 display: "flex",
//                 flexWrap: "nowrap",
//                 "& > :not(style)": {
//                   m: 1,
//                   width: "120%",
//                   height: "150px",
//                 },
//               }}
//             >
//               <Button size="1">
//                 <Paper
//                   variant="outlined"
//                   sx={{
//                     border: ".5rem outset #B88746",
//                     backgroundColor: "#3a3a3a",
//                   }}
//                 />
//               </Button>

//               {/* <MKButton size="large" /> */}
//               {/* </Paper> */}
//             </Box>
//           </Grid>
//           {/* <Grid item gridRow="TC" textAlign="center">
//             <Paper
//               sx={{
//                 backgroundImage: "url(assets/images/backgrounds/services landing/ma-abstract.jpg)",
//               }}
//             />
//           </Grid>
//           <Grid item gridRow="BC" textAlign="center">
//             <Paper
//               sx={{
//                 backgroundImage: "url(assets/images/backgrounds/services landing/ma-abstract.jpg)",
//               }}
//             />
//           </Grid>
//           <Grid item gridRow="CC" textAlign="center">
//             <Paper
//               sx={{
//                 backgroundImage: "url(assets/images/backgrounds/services landing/ma-abstract.jpg)",
//               }}
//             />
//           </Grid>
//           <Grid item gridRow="AP" textAlign="center">
//             <Paper
//               sx={{
//                 backgroundImage: "url(assets/images/backgrounds/services landing/ma-abstract.jpg)",
//               }}
//             />
//           </Grid> */}
//         </Grid>
//       </Container>
//     </MKBox>
//   );
// }

// export default ServiceAreas;
