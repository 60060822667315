/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Material Kit 2 React components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
// import bgImage from "assets/images/illustrations/illustration-reset.jpg";

// Google reCaptcha
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

import { sendAmplitudeData } from "utilities/amplitude";

function ContactUs() {
  sendAmplitudeData("PAGE_LOAD_CONTACT_US");
  const form = useRef();
  const [open, setOpen] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const messageMinimumLength = 25;
  // Checkboxes
  const [state, setState] = useState({
    ccp: false,
    erc: false,
    ma: false,
    bc: false,
    tc: false,
  });

  const { ccp, erc, ma, bc, tc } = state;

  const checkboxValid = [ccp, erc, ma, bc, tc].filter((v) => v).length >= 1;

  const handleCheckboxChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  const normalizePhoneInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(
        6,
        10
      )}`;
    }
    return value;
  };
  const RenderAlert = () => {
    if (open) {
      return (
        <MKAlert color="background" dismissible>
          Your message has been sent. We will get back to you as soon as possible.
        </MKAlert>
      );
    }
    return "";
  };
  const sendEmail = (e) => {
    if (
      checkboxValid &&
      nameValid &&
      messageValid &&
      emailValid &&
      (phoneValid || phoneValue === "")
    ) {
      e.preventDefault();
      setOpen(!open);
      emailjs
        .sendForm("service_rqsh52s", "template_3mzmbm2", form.current, "C9GO-neJKiCWgLu51")
        .then(form.current.reset());
    }
  };
  // Just make sure name isn't empty
  const handleNameValidation = (e) => {
    setNameValue(e.target.value);
    setNameValid(e.target.value !== "");
  };
  // Make sure message is a minimum of messageMinimumLength
  const handleMessageValidation = (e) => {
    setMessageValue(e.target.value);
    setMessageValid(e.target.value.length >= messageMinimumLength);
  };
  const handleEmailValidation = (e) => {
    // set value to user input
    setEmailValue(e.target.value);

    // Email ReGex
    const reg = new RegExp(
      "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
    );

    // test whether input is valid
    setEmailValid(reg.test(e.target.value));
  };
  const handlePhoneValidation = (e) => {
    // normalize input
    const normalizedPhone = normalizePhoneInput(e.target.value, phoneValue);

    // set value to user input
    setPhoneValue(normalizedPhone);

    // Phone ReGex
    const reg = new RegExp("^[+]?[(][0-9]{3}[)][ ][0-9]{3}[-][0-9]{4}$");

    // test whether input is valid
    setPhoneValid(reg.test(normalizedPhone));
  };

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey="6Lee8AQfAAAAAAQtjPddeXf5YoZVMuLwsVfa96E1">
        <DefaultNavbar routes={routes} transparent light />
        <Grid container bgcolor="#191919" alignItems="center" justifyContent="center">
          <Grid item alignItems="center" justifyContent="center">
            <MKBox
              bgColor="#2A2A2A"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 20, sm: 18, md: 20 }}
              mx={3}
            >
              <MKBox bgColor="black" borderRadius="lg" p={2} mx={2} mt={-3}>
                <MKTypography variant="h3" color="primary" textAlign="center">
                  Contact us
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKTypography variant="body2" color="text" mb={3}>
                  For further questions please use the contact form to send us an email and we will
                  get back to you promptly.
                </MKTypography>
                <MKBox
                  width="100%"
                  component="form"
                  id="emailForm"
                  ref={form}
                  method="post"
                  autocomplete="on"
                  onSubmit={sendEmail}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        required
                        color="white"
                        variant="standard"
                        label="Full Name"
                        name="fullName"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={nameValue}
                        onChange={(e) => handleNameValidation(e)}
                        error={!nameValid}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        required
                        type="email"
                        variant="standard"
                        label="Email"
                        name="fromEmail"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={emailValue}
                        onChange={(e) => handleEmailValidation(e)}
                        error={!emailValid}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="tel"
                        variant="standard"
                        label="Phone Number"
                        name="fromPhone"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={phoneValue}
                        onChange={(e) => handlePhoneValidation(e)}
                        // the next line would throw error if it was just "&& phoneValue"
                        error={!phoneValid && phoneValue !== ""}
                      />
                    </Grid>
                    <FormControl
                      required
                      error={!checkboxValid}
                      component="fieldset"
                      sx={{ m: 3 }}
                      variant="standard"
                    >
                      <FormLabel sx={{ fontSize: "60%" }}>Please check at least 1</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={ccp} onChange={handleCheckboxChange} name="ccp" />
                          }
                          label="Zero Cost Credit Card Processing"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={erc} onChange={handleCheckboxChange} name="erc" />
                          }
                          label="Receive up to $26,000 per employee"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={ma} onChange={handleCheckboxChange} name="ma" />
                          }
                          label="Buy/Sell Dealerships"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={bc} onChange={handleCheckboxChange} name="bc" />
                          }
                          label="Business Consulting"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={tc} onChange={handleCheckboxChange} name="tc" />
                          }
                          label="Streamline Your IT"
                        />
                      </FormGroup>
                    </FormControl>
                    <Grid item xs={12}>
                      <MKInput
                        required
                        variant="standard"
                        label="How can we help you? (minimum 25 chars)"
                        name="message"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                        value={messageValue}
                        onChange={(e) => handleMessageValidation(e)}
                        error={!messageValid}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <GoogleReCaptcha onVerify={() => {}} />
                    <MKButton type="submit" variant="outlined" color="gold" onClick={sendEmail}>
                      Send Message
                    </MKButton>
                  </Grid>
                  <RenderAlert />
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </GoogleReCaptchaProvider>
      <MKBox pt={6} px={1} mt={6} bgColor="black">
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
