/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="primary"
                    icon="transform"
                    title="Simple"
                    description="Our process only requires you to answer a few questions to get started!"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    color="primary"
                    icon="integration_instructions"
                    title="Recapture COVID losses"
                    description="We can help you get back what COVID took from you"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    color="primary"
                    icon="attach_money"
                    title="Up to $26,000 per employee"
                    description="Receive up to $26,000 per employee retained through the pandemic"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    color="primary"
                    icon="credit_card"
                    title="Quick"
                    description="We help eliminate any friction to get your claimed filed and processed quickly"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={{ xs: 5, md: 0 }}>
              <FilledInfoCard
                icon=""
                title=""
                description="Get up to $26,000 per employee retained through the COVID pandemic. Our process is very quick and simple and allows you to get credit for your hard work during COVID in keeping employees on through hard time.
                Contact us today to get started!"
                action={{
                  type: "internal",
                  route: "/contact-us",
                  color: "gold",
                  label: "Find Out More",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
